<template>
  <v-container style="max-width: 800px">
    <v-card>
      <v-card-title>
        <h4 class="text-center w-full">POLÍTICA DE PRIVACIDADE</h4>
      </v-card-title>
      <v-card-text class="px-4">
        <p>
          Esta Política de Privacidade descreve como a TicketMe coleta, usa,
          armazena e compartilha suas informações pessoais ao utilizar nosso
          aplicativo (&quot;Aplicativo&quot;). Ao utilizar o Aplicativo, você
          concorda com esta Política de Privacidade. Caso não concorde com esta
          Política, por favor, não utilize o Aplicativo.
        </p>
        <h5>INFORMAÇÕES QUE COLETAMOS</h5>
        <p>
          Ao utilizar o Aplicativo, podemos coletar as seguintes informações
          pessoais sobre você:
        </p>
        <p>
          a) Informações de registro: ao criar uma conta no Aplicativo,
          coletamos informações como nome, endereço de e-mail, telefone e senha.
        </p>
        <p>
          b) Informações de transação: quando você realiza a compra de ingressos
          ou outros produtos no Aplicativo, coletamos informações como nome,
          e-mail, telefone, documento, forma de pagamento e detalhes da
          transação.
        </p>
        <p>
          c) Informações de uso: coletamos informações sobre como você utiliza o
          Aplicativo, como dados de acesso, páginas visitadas e interações com o
          conteúdo do Aplicativo.
        </p>
        <h5>USO DAS INFORMAÇÕES</h5>
        <p>Utilizamos as informações coletadas para:</p>
        <p>
          a) Fornecer, melhorar e personalizar o Aplicativo e os serviços que
          oferecemos.
        </p>
        <p>b) Processar transações e gerenciar suas compras.</p>
        <p>
          c) Comunicar-se com você sobre eventos, promoções e atualizações do
          Aplicativo.
        </p>
        <p>
          d) Proteger a segurança e integridade do Aplicativo e de nossos
          usuários.
        </p>
        <p>e) Cumprir nossas obrigações legais e regulatórias.</p>
        <h5>COMPARTILHAMENTO DE INFORMAÇÕES</h5>
        <p>
          Ao iniciar uma compra no Aplicativo, você concorda em compartilhar
          suas informações pessoais com a organização do evento para o qual está
          comprando ingressos. As informações compartilhadas podem incluir nome,
          e-mail, telefone, documento e forma de pagamento.
        </p>
        <p>Além disso, podemos compartilhar suas informações pessoais:</p>
        <p>
          a) Com prestadores de serviços que nos ajudam a operar e melhorar o
          Aplicativo.
        </p>
        <p>
          b) Quando exigido por lei ou para proteger os direitos, propriedade ou
          segurança da TicketMe, de nossos usuários ou de terceiros.
        </p>
        <p>
          c) Em conexão com uma fusão, aquisição, venda de ativos ou
          reorganização corporativa envolvendo a TicketMe.
        </p>
        <h5>SEGURANÇA</h5>
        <p>
          Empregamos medidas de segurança razoáveis para proteger suas
          informações pessoais contra acesso, uso e divulgação não autorizados.
          No entanto, nenhum método de transmissão ou armazenamento de dados é
          100% seguro, e não podemos garantir a segurança absoluta de suas
          informações pessoais.
        </p>
        <h5>RETENÇÃO DE DADOS</h5>
        <p>
          Armazenamos suas informações pessoais pelo tempo necessário para
          cumprir os propósitos descritos nesta Política de Privacidade ou
          conforme exigido por lei.
        </p>
        <h5>DIREITOS DO USUÁRIO</h5>
        <p>
          Você tem o direito de acessar, corrigir, atualizar, excluir ou
          restringir o uso de suas informações pessoais. Para exercer esses
          direitos, entre em contato conosco através dos canais de atendimento
          disponíveis no Aplicativo.
        </p>
        <h5>ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h5>
        <p>
          Podemos atualizar esta Política de Privacidade periodicamente para
          refletir mudanças em nossas práticas de coleta, uso e compartilhamento
          de informações pessoais. As alterações entrarão em vigor imediatamente
          após a publicação das alterações no Aplicativo. Ao continuar a
          utilizar o Aplicativo após a entrada em vigor de tais alterações, você
          concorda em cumprir a Política de Privacidade conforme alterada.
        </p>
        <h5>TRANSFERÊNCIA INTERNACIONAL DE DADOS</h5>
        <p>
          O Aplicativo pode ser operado em servidores localizados em diferentes
          países. Ao utilizar o Aplicativo e fornecer suas informações pessoais,
          você concorda com a transferência, armazenamento e processamento de
          suas informações pessoais fora do seu país de residência, conforme
          necessário para fornecer os serviços do Aplicativo.
        </p>
        <h5>PRIVACIDADE DE MENORES</h5>
        <p>
          O Aplicativo não é destinado a menores de 18 anos e não coletamos
          intencionalmente informações pessoais de menores de 18 anos. Se
          tomarmos conhecimento de que coletamos informações pessoais de um
          menor de 18 anos, tomaremos medidas para remover essas informações e
          encerrar a conta do menor.
        </p>
        <h5>CONTATO</h5>
        <p>
          Se você tiver alguma dúvida ou preocupação em relação a esta Política
          de Privacidade ou ao Aplicativo, entre em contato conosco por meio dos
          canais de atendimento disponíveis no Aplicativo.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>
</template>

<script>
export default {};
</script>

<style>
</style>